@import url(https://fonts.googleapis.com/css?family=Poppins);
html,
body {
  height: 100%
}

@media only screen and (max-width: 992px) {
  body {
    padding-top: 100px !important;
  }
}

@media only screen and (min-width: 992px) {
  body {
    padding-top: 55px !important;
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;

}

.react-tel-input .form-control {
  width: -webkit-fill-available !important;
}

.bg {
  background-image: black;
}

.buyukekranbrand {
  font-size: 1.25rem !important;
}

.kucukekranbrand {
  font-size: 1rem !important;
}

/*::-webkit-scrollbar {
  display: none;
}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover {
  text-decoration: none;
  color: #ff0000 !important;
}

a {
  display: inline-block;
  transition: all 0.5s;
  text-decoration: none;
  color: #000000;
  font-size: 16px;
}


#arama.dropdown-toggle::after {
  display: none;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 0, 0) !important;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(255, 0, 0) !important;
}

#arama {
  display: inline-block;
  margin-right: 10px;
}

#sag .dropdown-menu {
  border: 0;
  background-color: transparent !important;
}

#sag .dropdown-item:hover {
  background-color: transparent !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
}

.w3rcontainer {
  border: 1px solid #cccfdb;
  border-radius: 2px;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #ffffff;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ff0000;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.golgeli {
  text-shadow: 3px 3px 3px rgb(0, 0, 0);
}

h1 {
  font-size: 30px !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
  letter-spacing: 0.8px !important;
}

h2 {
  font-weight: 600 !important;
  font-size: 28px !important;
  letter-spacing: 1.1px !important;
}

h3 {
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-bottom: 0px !important;
  letter-spacing: 0.8px !important;
}

h4 {
  font-size: 28px !important;
  font-weight: 600 !important;
  margin-bottom: 12px !important;
  letter-spacing: 0.8px !important;
}

h5 {
  font-size: 18px !important;
  font-weight: 400 !important;
  margin-bottom: 2px !important;
}

h6 {
  font-size: 14px !important;
  color: #97989b !important;
  margin-bottom: 0px !important;
  font-weight: 500 !important;
  letter-spacing: 0.6px !important;
  line-height: 1.5 !important;
  padding-top: 5px !important;
}


i {
  color: #97989b;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
}

.morelink h1 {
  font-size: 13px;
  font-weight: 500;
  color: #97989b;
  letter-spacing: 0;
  margin: 0;
}



/*.offcanvas-top {
  height: fit-content !important;
  background-color: black !important;
}*/
.offcanvas-top {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: black;
}

.resimyukseklikdivi {
  width: 100%;
  height: calc(100vh - 70px)
}

.resim {
  height: 100%;
}

/*resme karanlık çerçeve ver*/
.resimcerceve {
  position: absolute;
  width: 100%;
  height: 102%;
  background: rgba(0, 0, 0, .5)
}

.carousel-control-prev {
  width: 10% !important;
  color: #fff !important;

}

.carousel-control-next {
  width: 10% !important;

}

/* caruseldeki başlığı sola hizala */
.caruselbaslik {
  text-align: start !important;

}

/* floating action buttonu sadece mobilde göster */
@media (min-width:576px) {
  .fab {
    display: none !important;
  }
}

.fab {
  position: fixed !important;
  bottom: 0px !important;
  right: 0px !important;
  z-index: 9999 !important;
}


.accordion-button {
  background-color: #272c33 !important;
  color: white !important;
  font-size: 15px !important;
}

.accordion-button.collapsed {
  background-color: #151719 !important;
  color: white !important;
  font-size: 15px !important;
}

.accordion-collapse.collapse {
  background-color: #272c33 !important;
  color: white !important;
  font-size: 14px !important;

}

.accordion-collapse.collapsing {
  background-color: #272c33 !important;
  color: white !important;
  font-size: 14px !important;

}

.accordion {
  background-color: #272c33 !important;
  color: white !important;
  font-size: 14px !important;

}

@media only screen and (max-width: 767px) {
  .accordion-button {
    font-size: 13px !important;
  }

  .accordion-button.collapsed {
    font-size: 13px !important;
  }

  .accordion-collapse.collapse {
    font-size: 12px !important;
  }

  .accordion {
    font-size: 12px !important;
  }

  .accordion-collapse.collapsing {
    background-color: #272c33 !important;
    color: white !important;
    font-size: 12px !important;
  }
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}

.trainees-says {
  position: relative;
  z-index: 1;
  height: 32em;
  margin-bottom: 1em;
}

.trainees-says-box {
  position: absolute;
  z-index: 3;
  background-color: #272c33;
  width: 640px;
  left: 80px;
  height: 330px;
  top: 61px;
}

.trainees-says-img {
  position: absolute;
  z-index: 2;
  width: 360px;
  left: 60%;
  top: 0.5em;
  height: 460px;
  opacity: 0.9;
}

.trainees-says-button {
  position: absolute;
  z-index: 3;
  width: 20%;
  left: 2.1em;
  height: 4em;
  top: 26.8em;
}

.trainees-says-box h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 17px !important;
  line-height: 30px;
  margin-bottom: 0;
}

.modal-content {
  color: #000 !important;
}

.slick-dots li button:before {
  color: rgb(255, 255, 255) !important;

}

.cizgi-indicators {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  height: 50px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.trainees-says-text {
  height: 210px;
  background-color: #272c33;
}

hr {
  opacity: 1 !important;
}

.knd {
  position: relative;
  border-radius: 10px;
  border: 1px solid rgb(255, 255, 255);
  padding: 10px !important;
  margin: 30px;
  background-color: #000;
  max-height: 100%;
}

.kndresim {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  width: 50%;
}

.kndaciklama {
  width: 100%;
  max-height: 55px !important;
  padding: 5px;
  overflow: hidden;
}

.slick-prev {
  left: 0px !important;
  z-index: 100;
}

.slick-next {
  right: 0px !important;
  z-index: 100;
}

#sertifikalar {
  padding-top: 20px;
  padding-bottom: 30px;
}

.altlogo {
  font-size: 20pt;
}

.altlogoimg {
  width: 80px;
}


#sizeozelegitimlerimiz {
  padding-top: 30px;
  padding-bottom: 30px;
}

#projelerimizsection {
  padding-top: 20px;
  padding-bottom: 30px;
}

#faaliyetlerimizsection {
  padding-top: 20px;
  padding-bottom: 30px;
}
#duyurularsection {
  padding-top: 20px;
  padding-bottom: 30px;
}

#iletisimsection {
  padding-top: 20px;
  padding-bottom: 30px;
}

#makalelerimizlistesi {
  padding-top: 30px;
  padding-bottom: 40px;
}

#sss {
  padding-top: 30px;
  padding-bottom: 40px;
}

#kursiyerlerimiznediyor {
  padding-top: 30px;
  padding-bottom: 40px;
}


#faaliyetlerimizmenusu~.dropdown-menu {
  width: -webkit-max-content;
  width: max-content;
  max-height: 70vh;
  overflow-y: auto;
}


@media only screen and (max-width: 767px) {
  #faaliyetlerimizmenusu~.dropdown-menu {
    width: 100%;
    max-height: 70vh;
    overflow-y: auto;
  }
}

.training-detail-content {
  position: relative;
}

.block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 100px);
  z-index: 1;
}

.arrow img {
  width: 40px;
  height: 40px;
  -webkit-filter: invert(29%) sepia(0%) saturate(3%) hue-rotate(39deg) brightness(96%) contrast(84%);
          filter: invert(29%) sepia(0%) saturate(3%) hue-rotate(39deg) brightness(96%) contrast(84%);
}

.hata {
  display: none !important;
}

.gizle {
  display: none !important;
}

.scroolbar {
  float: left;
  max-height: 60vh;
  height: auto;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 25px;
}

.force-overflow {
  min-height: 450px;
}


#style-2::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #000000;
}

#style-2::-webkit-scrollbar {
  width: 12px;
  background-color: #000000;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #ff0000;
}

.altikirmizicizili {
  text-decoration: underline;
  -webkit-text-decoration-color: red;
  /* Safari */
  text-decoration-color: red;
}

.faaliyetlerimizblok {
  padding: 5px;
  border-radius: 10px;
  background-color: #ffffff;
}

.egitmendiv {
  text-align: center;
  border-radius: 10px;
  background-color: #2b3038;
  padding: 15px;
  margin-bottom: 15px;
  min-height: 385px;
}

.blogdiv {
  text-align: center;
  border-radius: 10px;
  background-color: #e7e8ea;
  padding: 15px;
  margin-bottom: 15px;
  min-height: 250px;
}

.iletisimdiv {
  display: grid;
  border-radius: 5px;
  ;
  background-color: #2b3038;
  padding: 15px;
  vertical-align: bottom;
}

.border-bottom {
  border-bottom: 2px solid #ff0000 !important;
}

.border-top {
  border-top: 2px solid #ff0000 !important;
}

table {
  border: 1px solid white;
  margin-top: 20px;
}

table>thead>tr>th {
  border: 1px solid white;
}

table>tbody>tr>td {
  border: 1px solid white;
}

.byzlnk {
  color: white !important;
  text-decoration: none !important;
}

.syhlnk {
  color: black !important;
  text-decoration: none !important;
}

.syhlnk:hover {
  color: rgb(255, 0, 0) !important;
  text-decoration: none !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}

.route-section {
  position: absolute;
  left: 0;
  right: 0;
}

.kisa {
  text-align: justify;
}

.uzun {
  text-align: justify;
}

#loader {
  z-index: 9999;
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #d5eff9;
}

#loaderspan {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#loadervideo {
  width: 100%;
  height: 100%;
  position: fixed;
  /*object-fit: fill;*/
}

.egitimaramaogesicol {
  position: relative;
  height: auto;
  margin-bottom: 5px;
}

.egitimaramaogesikutu {
  background-color: rgb(46 51 55);
  border-radius: 10px;
  margin: 3px;
  padding: 10px;
  height: 100%;
}

.egitimaramaogesimetin {
  position: absolute;
  bottom: 10px;
  left: 0px;
  right: 0px;
}

.menuresmi {
  height: 50px;
}

.kurumsalresim {
  width: 100%;
}

.iletisimharitaalti {
  background-color: #ffffff;
}

.yazirengibeyaz {
  color: white;
}

.yuzdeyuzgenislik {
  width: 100%
}

.yuzdeelligenislik {
  width: 50%
}

.sagayasli {
  float: right;
}

.sagaltyasli {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.sagsolbosluk10 {
  padding-left: 10px;
  padding-right: 10px;
}

.sagsolbosluk30 {
  padding-left: 30px;
  padding-right: 30px;
}

.projeresmi {
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: 50% 10%;
}

.sertifikaresmi {
  margin: auto;
  height: 100px;
}

.sertifikaresmi2 {
  margin: auto;
  height: 60px;
}

.kndmodalbody {
  width: 35%;
  float: left;
  padding-inline: 15px;
}

.kirmizicizgidiv {
  display: block;
  height: 2px;
  width: 100%;
  background-color: red;
  margin-top: 10px;
  margin-bottom: 10px;
}

#scrolly{
  width: 100%;
  height: 190px;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 0 auto;
  white-space: nowrap
}

.modal-open {
  overflow-y: auto !important;
  overflow-x: hidden;
}
